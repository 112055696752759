import React from "react";
import "./style.scss";

export const Footer: React.FC = () => {
  return (
    <div className="footer">
      Firma Przewozowa BMC
    </div>
  );
};
